<template>
  <div>
    <div class="flix-text-center">
      <div style="display: flex; align-items: center; justify-content: center;">
        <b-flix-logo class="logo" variant="default" />
      </div>
    </div>
    <transition name="flixFadeIn">
      <div :key="animation">
        <flixLoader v-if="animation === 0" :text="$t('message.load', {name: $tc('message.calendar', 2)})"/>
        <flixLoader v-if="animation === 1" :text="$t('message.load', {name: $tc('message.customer', 2)})"/>
        <flixLoader v-if="animation === 2" :text="$t('message.load', {name: $t('message.appointmentOverview')})"/>
        <flixLoader v-if="animation === 3" :text="$t('message.load', {name: $tc('message.settings', 2)})"/>
        <flixLoader v-if="animation === 4" :text="$t('message.load', {name: $tc('message.appointments', 2)})"/>
        <flixLoader v-if="animation === 5" :text="$t('message.load', {name: $tc('message.appointmentOverview', 2)})"/>
        <flixLoader v-if="animation === 6" :text="$t('message.load', {name: $tc('message.pageBuilder', 2)})"/>
        <flixLoader v-if="animation === 7" :text="$t('message.load', {name: $tc('message.backup', 2)})"/>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      loaded: false,
      animation: 0
    }
  },
  computed: {

  },
  mounted () {
    if (this.$route.name.indexOf('dashboard') !== -1 && !this.$store.getters.user) {
      window.redirect = { name: this.$route.name, params: this.$route.params }
      if (this.$route.name !== 'signInTranslation') {
        this.$router.push({ name: 'signIn' })
      }
    } else {
      this.setAnimation()
      this.getLoadData()
    }
  },
  methods: {
    setAnimation () {
      if (this.loaded === true) {
        return false
      }
      setTimeout(function () {
        this.animation++
        if (this.animation > 7) {
          this.animation = 0
        }
        this.setAnimation()
      }.bind(this), 5000)
    },
    getLoadData () {
      if (typeof window.localStorage.getItem('flix_login') === 'string') {
        this.$store.commit('setUser', JSON.parse(atob(window.localStorage.getItem('flix_login'))))
        this.$store.dispatch('getAssistents', function () {
          this.$store.dispatch('getBookings', function () {
            this.$store.dispatch('getMessages', function () {
              this.loaded = true
              this.callback(true)
            }.bind(this))
          }.bind(this))
        }.bind(this))
      } else {
        this.loaded = true
        this.callback(true)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  .logo
    width: 200px !important
    margin-top: 100px
</style>
